.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.header {
  background-color: #282c34;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: left;

  color: antiquewhite;
  padding-left: 1em;
  align-items: baseline;

  background-color: #111;
}

.header h1 {
  font-size: 2em;
}

.header nav {
  display: flex;
  flex-grow: 1;
}

.header ul {
  display: flex;
  flex-direction: row;
  list-style: none;

  align-items: baseline;
}

.header ul li {
  margin: 0 10px;
}

.main {
  flex: 1;
  padding: 20px;
  background-color: #223;
}

.link {
  color: #61dafb;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}