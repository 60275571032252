.CardStackWrapper {
  /*
  remove all button styles
  */
  min-width: 85px;

  /* Negative margin to pull items closer */
  margin: 2px;

  min-height: 400px;
  border-radius: 10px;
}

.LooserStack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LooserStack button {
  margin-bottom: -120%;
  transform: rotate(2deg);
}

.DeckStack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DeckStack button {
  margin-bottom: -150%;
  transform: rotate(2deg);
}

.Tilty {
  transform: rotate(2deg);
}