.PokerCard {
  border-style: none;
  /* transform: rotate(3deg); */
  /*makes it easier to see the overlap*/

  width: 100%;

  /* 5:7 Aspect Ratio - playing card */
  padding-top: 140%;

  position: relative
}


.ClickableCard {
  /*
  remove all button styles
  */
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  /* width: 1000px; */
  background-color: red;
}